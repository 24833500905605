module directives {
    export module logistics {
        interface ILiveTrackingScope extends ng.IScope {
            maxViewCount:number;
            consignmentId: number;
            manifestId: number;
            liveTrackingData : interfaces.logistics.ILiveTrackingViewModel[];
            GetLiveTrackingData();            
            OpenLiveTrackingWindow(url:string);
            ViewMore();
        }

        export class liveTrackingDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/logistics/liveTrackingView.html';
            scope = {
                consignmentId: "=?",
                manifestId: "=?",
            }

            messages: interfaces.applicationcore.IMessage[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                private liveTrackingService: interfaces.logistics.ILiveTrackingService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private menuService: interfaces.applicationcore.IMenuService,                
            ) {

            }

            link = ($scope: ILiveTrackingScope, $element: ng.IAugmentedJQuery, $state) => {

                $scope.GetLiveTrackingData = () => {
                    return this.liveTrackingService.getLiveTrackingData($scope.consignmentId,$scope.manifestId).query({
                    }, (resultList: interfaces.logistics.ILiveTrackingViewModel[]) => {

                        resultList = resultList.map((item) => {
                            // Update the desired field within each object
                            if (item.TrackingId && item.LatestETA)
                                item.Tooltip = "ETA: " + moment(item.LatestETA).format("YYYY/MM/DD HH:mm") + " <br/> Tracking Id: " + item.TrackingId;
                            else
                            item.Tooltip = "Live Tracking not available.";
                            
                            // Return the updated object
                            return item;
                          });
                            $scope.liveTrackingData = resultList;
                    }).$promise;
                }

                $scope.GetLiveTrackingData();    

                $scope.OpenLiveTrackingWindow = (url:string) => {
                    window.open(url, '_blank').focus();
                }

                $scope.ViewMore = () => {
                    $scope.maxViewCount =  $scope.liveTrackingData.length;
                }

                $scope.maxViewCount = 3;
            }


            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, LiveTrackingService, $state,
                    bsLoadingOverlayService, menuService) => new liveTrackingDirective(generalService, $uibModal, LiveTrackingService, $state, bsLoadingOverlayService, menuService);
                directive.$inject = ["generalService", "$uibModal", "LiveTrackingService", "$state", "bsLoadingOverlayService", "menuService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsLiveTracking", liveTrackingDirective.factory());
    }
}